import {
	DrivenBox,
	DrivenBrandType,
	DrivenNavigation,
	NavigationItem,
} from '@iterateai/driven-components';
import { motion } from 'framer-motion';
import { useMediaQuery, useTheme } from '@mui/material';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import '/static/assets/styles/global.css';
import React, { useEffect, useState } from 'react';
import { GatsbyLinkBehavior as GatsbyLink } from '../components/gatsby-link-behavior';
import { navigationItems } from '../hooks/use-navigation';
import Footer from './footer';

interface LayoutProps {
	children: React.ReactNode;
	pageContext: any;
}

export function Layout({ children, pageContext }: LayoutProps) {
	const [loaded, setLoaded] = useState(false);
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.only('md'));

	useEffect(() => {
		setLoaded(true);
	}, []);

	const navTypography = isTablet ? 'body2Bold' : 'body1Bold';

	return (
		<React.Fragment>
			<SkipNavLink />
			<DrivenBox sx={{ minHeight: '100vh' }}>
				{loaded && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<DrivenBox
							sx={{
								'.MuiAppBar-root': {
									// Hack to use smaller DB logo, matching Figma design
									// and allowing more links to fit without line wrapping
									// at medium viewport widths.
									// TODO fix this in the design system component.
									'a[href="/"] .MuiBox-root': {
										// Using 32px instead of 40 provides optical centering
										// for the visual weight of the Driven Brands logo
										height: '32px',
									},
									'svg:not([class*="svg-inline"])': {
										height: '40px',
									},

									// Smaller icons help the links fit on small tablet screens
									i: {
										[theme.breakpoints.only('md')]: {
											fontSize: '20px',
											minWidth: '20px',
										},
									},

									// Add hover state to menu items
									// '.MuiList-root': {
									// 	'a:hover': {
									// 		backgroundColor: theme.palette.grey['900'],
									// 	},
									// },

									// Make active navigation links display correctly on desktop
									[theme.breakpoints.up('md')]: {
										'.active-link, .MuiDrawer-paper .active-link': {
											color: theme.palette.secondary.main,

											'&::after': {
												backgroundColor: theme.palette.secondary.main,
											},
										},
									},
								},
							}}
						>
							<DrivenNavigation
								dark={true}
								brandColor='secondary'
								showSubNav={false}
								navigationItems={navigationItems as NavigationItem[]}
								brandLogo={DrivenBrandType.DrivenBrands}
								typography={navTypography}
								LinkComponent={GatsbyLink}
							/>
							<SkipNavContent />
							<DrivenBox
								minHeight='80vh'
								sx={{
									borderTopWidth: { xs: '75px', md: '91px' }, // Magic numbers to match navbar height
									borderTopColor: 'rgb(0,0,0)',
									borderTopStyle: 'solid',
								}}
							>
								{children}
							</DrivenBox>
						</DrivenBox>
						<Footer />
					</motion.div>
				)}
			</DrivenBox>
		</React.Fragment>
	);
}

export default Layout;
