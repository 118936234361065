const useLinkHref = (link: any) => {
	if (link) {
		// Prefer linking internally by selecting a page
		if (link.page) {
			return link.page.path;
		}

		if (link.asset) {
			return link.asset.url;
		}

		// Typically a full, external URL. Ex: 'https://apple.com'
		if (link.url) {
			return link.url;
		}
	}

	return '';
};

export default useLinkHref;
