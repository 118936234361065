declare global {
	interface Window {
		dataLayer?: any;
	}
}

import { isActive } from '@iterateai/driven-components';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import * as React from 'react';

export type CustomReachLinkProps = Omit<GatsbyLinkProps<unknown>, 'ref'> & {
	active?: boolean;
};

export const captureCustomDataLayerEvent = (
	event: string,
	additionalEventProps: any
) => {
	window.dataLayer = window.dataLayer || [];
	// console.log('Pushing %s: %s', event, additionalEventProps);
	window.dataLayer.push({ event, ...additionalEventProps });
};

export const GatsbyLinkBehavior = React.forwardRef<
	HTMLAnchorElement,
	Omit<CustomReachLinkProps, 'to'> & { href: CustomReachLinkProps['to'] }
>(
	// eslint-disable-next-line react/prop-types
	({ className = '', href, ...props }, ref) => {
		// Gatsby Link should not be used for exernal links
		// @source: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#use-link-only-for-internal-links
		if (href && href.indexOf('http' || 'https') !== -1) {
			return (
				<a
					className={className}
					href={href}
					ref={ref}
					rel='noreferrer'
					target='_blank'
					title={`External link to ${href}`}
					{...props}
				>
					{props.children}
				</a>
			);
		}
		if (href && href.indexOf('tel:') !== -1) {
			return (
				<a
					className={className}
					ref={ref}
					href={href}
					title={`Phone number ${href}`}
					onMouseUp={() => {
						// Note: onMouseUp because onClick is blocked by default browser behavior
						captureCustomDataLayerEvent('click_to_call');
					}}
					{...props}
				>
					{props.children}
				</a>
			);
		}
		return (
			<GatsbyLink
				innerRef={ref}
				to={href}
				title={`Link to ${href}`}
				getProps={({ isCurrent, isPartiallyCurrent }) =>
					isActive(className, isCurrent, isPartiallyCurrent)
				}
				{...props}
			/>
		);
	}
);
GatsbyLinkBehavior.displayName = 'GatsbyLink';
