/* eslint-disable react/prop-types */
import { DrivenTypography } from '@iterateai/driven-components';
import { styled, TypographyProps } from '@mui/material';
import * as React from 'react';

export interface HeadingProps {
	text: string;
	logo?: {
		id: string;
		publicUrl: string;
		placeholderUrl: string;
		gatsbyImageData: Object;
	};
	level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	style: TypographyProps['variant']; // TODO enum of allowable style variants from the theme?
	className?: string;
}

// const StyledHeading = styled(DrivenTypography)(() => ({}));
type ImageProps = {
	sizes: string;
	src: string;
	srcSet: string;
	type?: 'string';
};

interface LogoProps {
	id: string;
	publicUrl: string;
	placeholderUrl: string;
	gatsbyImageData: {
		backgroundColor: string;
		height: number;
		images: {
			fallback: ImageProps;
		};
		sources: Array<ImageProps>;
	};
	alt?: string;
}

// Use styled to gain sx & attribute styling when <Heading> is used.
const StyledDrivenTypography = styled(DrivenTypography)(() => ({}));

const StyledLogo = styled('img')(() => ({
	display: 'inline-block',
	height: '96px',
}));

const logoFormat = (filename: string) => {
	// TODO determine 'svg' or 'bitmap'
	return 'bitmap';
};

const Logo = ({
	publicUrl,
	placeholderUrl,
	gatsbyImageData,
	alt = '',
}: LogoProps) => {
	const format = logoFormat(publicUrl);

	if (format === 'svg') {
		// TODO implement SVG image
		return null;
	}

	return <StyledLogo src={publicUrl} alt={alt} />;
};

const Heading = ({
	name,
	text,
	logo,
	level = 'h2',
	style = 'h4',
	className = '',
	...props
}: HeadingProps) => {
	return (
		<StyledDrivenTypography
			component={level}
			variant={style}
			className={className}
			{...props}
		>
			<React.Fragment>
				{logo?.publicUrl ? (
					<Logo alt={text} {...logo} />
				) : (
					<React.Fragment>{text}</React.Fragment>
				)}
			</React.Fragment>
		</StyledDrivenTypography>
	);
};

export default Heading;
