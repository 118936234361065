import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { DrivenThemeProvider } from '@iterateai/driven-components';
import { DrivenBrands } from '@iterateai/driven-tokens';
import { GlobalStyles } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import * as React from 'react';
import type { GatsbyBrowser, RouteUpdateArgs } from 'gatsby';
import Layout from './src/components/layout';
import '@fontsource/montserrat/variable.css';
import '@fontsource/montserrat/variable-italic.css';

config.autoAddCss = false;

export const onRouteUpdate = ({
	prevLocation,
	location,
}: RouteUpdateArgs): void => {
	if (prevLocation !== null) {
		const skipLink: any = document.querySelector('#reach-skip-nav');

		if (skipLink) {
			skipLink.focus();
		}
	}
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
	element,
}) => {
	return (
		<DrivenThemeProvider theme={DrivenBrands.DrivenBrands}>
			<GlobalStyles
				styles={{
					body: {
						margin: 0,
						minHeight: '100vh',
						padding: 0,
					},
				}}
			/>
			<AnimatePresence mode='wait'>{element}</AnimatePresence>
		</DrivenThemeProvider>
	);
};

if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
	window.navigator.serviceWorker.getRegistrations().then((registrations) => {
		registrations.forEach((r) => r.unregister());
	});
}

export const wrapPageElement = ({ element, props }) => {
	// props provide same data to Layout as Page element will get
	// including location, data, etc - you don't need to pass it
	return <Layout {...props}>{element}</Layout>;
};
