import {
	DrivenBox,
	DrivenContainer,
	DrivenGrid as Grid,
	DrivenTypography,
} from '@iterateai/driven-components';
import { useTheme } from '@mui/material';
import Heading from './heading';
import useLinkHref from '../hooks/use-link-href';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';
import uuid4 from 'uuid4';
import Svg from './use-svg';

interface ImageProps {
	__typename?: string;
	mimeType?: string;
	publicUrl: string;
	alt?: string;
	image: any;
}

interface LinkImageProps {
	__typename?: string;
	link: any;
	image: ImageProps;
}
interface ImageBannerProps {
	images?: [ImageProps | LinkImageProps];
	heading: string;
}

const BannerImage: any = ({ image, alt }: any) => {
	const gatsbyImage = getImage(image?.gatsbyImageData);
	if (!gatsbyImage) {
		return;
	}
	return (
		<GatsbyImage
			image={gatsbyImage}
			alt={alt}
			objectFit='contain'
			imgStyle={{ maxWidth: '168px' }}
		/>
	);
};

const Image: any = ({ image, alt }: any) => (
	<DrivenBox>
		{image?.mimeType === 'image/svg+xml' ? (
			<Svg {...image} />
		) : (
			<BannerImage image={image} alt={alt} />
		)}
	</DrivenBox>
);

const LinkImage: any = ({ link, image, alt = '' }: any) => (
	<GatsbyLink href={useLinkHref(link)} target={link.target}>
		<Image image={image} alt={alt} />
	</GatsbyLink>
);

function ImageBanner({ images, heading }: ImageBannerProps) {
	const theme = useTheme();
	const uid = uuid4();

	return (
		<DrivenBox py={3}>
			<DrivenContainer>
				<DrivenBox textAlign='center' mb={2}>
					<Heading text={heading} style='h6' level='h2' />
				</DrivenBox>

				<DrivenBox
					sx={{
						display: 'flex',
						justifyContent: 'center', //'space-evenly',
						flexWrap: 'wrap',
						columnGap: theme.spacing(3),
						rowGap: theme.spacing(2),

						'[data-gatsby-image-wrapper]': {
							maxWidth: '168px',
						},
					}}
				>
					{images?.map((image: ImageProps | LinkImageProps, index: number) => {
						if (image.__typename === 'ContentfulLinkImage') {
							return (
								<LinkImage
									link={image.link}
									image={image.image}
									alt={image.altText}
									key={uid + index}
								/>
							);
						}
						return (
							<Image image={image} alt={image.altText} key={uid + index} />
						);
					})}
				</DrivenBox>
			</DrivenContainer>
		</DrivenBox>
	);
}

export default ImageBanner;
