import 'external-svg-loader';
import * as React from 'react';

const Svg = ({ url }: any) => {
	return (
		<svg
			data-src={url}
			fill='currentColor'
			height='100%'
			width='100%'
			style={{
				color: 'currentColor',
			}}
		/>
	);
};

export default Svg;
