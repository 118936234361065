export const navigationItems = [
	{
		text: 'About',
		href: '/about',
		submenu: [
			{
				text: 'About Driven Brands',
				href: '/about',
			},
			{ text: 'Charitable Foundation', href: '/foundation' },
		],
	},
	{
		text: 'Careers',
		href: 'https://careers.drivenbrands.com/',
		target: '_blank',
	},
	{ text: 'Newsroom', href: '/news' },
	{ text: 'Franchise Program', href: '/franchise' },
	{
		text: 'Acquisitions',
		// href: '/#',
		submenu: [
			{
				text: 'Sell Your Quick Lube',
				href: '/sell-your-center',
			},
			{
				text: 'Sell Your Car Wash',
				href: '/sell-your-carwash',
			},
			{
				text: 'Sell Your Glass Center',
				href: '/sell-your-glasscenter',
			},
		],
	},
	{
		text: 'Investors',
		href: 'https://investors.drivenbrands.com',
		icon: 'arrow-right-long',
	},
	{
		text: 'Driven Fleet',
		href: 'https://www.drivenfleet.com',
		icon: 'arrow-right-long',
	},
];
