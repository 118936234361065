import { DrivenBox, DrivenTypography } from '@iterateai/driven-components';
import ReactMarkdown from 'react-markdown';
import { useTheme } from '@mui/material';
import Heading from './heading';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import * as React from 'react';

const getNodeText = (node) => {
	if (['string', 'number'].includes(typeof node)) return node;
	if (node instanceof Array) return node.map(getNodeText).join('');
	if (typeof node === 'object' && node) return getNodeText(node.props.children);
};

const EmbedContainer = ({ theme, children }) => (
	<DrivenBox
		my={3}
		maxWidth='100%'
		sx={{
			'> iframe': {
				maxWidth: '100%',
			},
		}}
	>
		{children}
	</DrivenBox>
);

const MarkdownContent = ({
	children,
	paragraphMarginY = '1em',
	allowHtml = false,
}) => {
	const rehypePlugins = [rehypeSlug, rehypeAutolinkHeadings];
	const theme = useTheme();

	if (allowHtml) {
		rehypePlugins.push(rehypeRaw);
	}
	return (
		<ReactMarkdown
			rehypePlugins={rehypePlugins}
			components={{
				h1: (props) => (
					<Heading
						style
						level='h1'
						variant='h1'
						id={props.id}
						sx={{
							fontWeight: '700',
						}}
						text={getNodeText(props.children)}
					/>
				),
				hr: (props) => <DrivenTypography component='hr' mt='4rem' {...props} />,
				h2: (props) => (
					<a name={props.id}>
						<Heading
							style='h3'
							level='h2'
							sx={{}}
							mt='3rem'
							mb='1rem'
							id={props.id}
							text={getNodeText(props.children)}
						/>
					</a>
				),
				h3: (props) => (
					<a name={props.id}>
						<Heading
							style='subtitle2'
							level='h3'
							sx={{}}
							mt='3rem'
							mb='1rem'
							id={props.id}
							text={getNodeText(props.children)}
						/>
					</a>
				),
				h4: (props) => (
					<a name={props.id}>
						<Heading
							style='body2'
							level='h4'
							sx={{}}
							mt='3rem'
							mb='1rem'
							id={props.id}
							text={getNodeText(props.children)}
						/>
					</a>
				),
				ul: function CustomUnorderedList({ ordered, ...props }) {
					return (
						<DrivenTypography
							component='ul'
							ordered={`${ordered}`}
							sx={{ display: 'block', listStyleType: 'disc' }}
							mt='1.25rem'
							mb='1.45rem'
							{...props}
						/>
					);
				},
				ol: function CustomOrderedList({ ordered, ...props }) {
					return (
						<DrivenTypography
							component='ol'
							ordered={`${ordered}`}
							sx={{ display: 'block' }}
							mt='1.25rem'
							mb='1.45rem'
							{...props}
						/>
					);
				},
				li: ({ ordered, ...props }) => (
					<DrivenTypography
						variant='body1'
						component='li'
						ordered={`${ordered}`}
						{...props}
					/>
				),
				p: (props) => {
					const isEmbed =
						allowHtml &&
						props.node?.children[0]?.properties?.className?.includes(
							'embedly-card'
						)
							? true
							: false;

					if (isEmbed) {
						return (
							<EmbedContainer theme={theme}>{props.children}</EmbedContainer>
						);
					}

					return (
						<DrivenTypography
							paragraph
							variant='body1'
							{...props}
							my={paragraphMarginY}
						/>
					);
				},
				iframe: (props) => (
					<EmbedContainer theme={theme}>
						{React.createElement('iframe', props)}
					</EmbedContainer>
				),
				blockquote: (props) => (
					<DrivenTypography
						component='blockquote'
						variant='blockquote'
						sx={{
							margin: '1.5rem 0',
							backgroundColor: 'background.default',
							color: 'text.secondary',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
							position: 'relative',
							'&::before': {
								position: 'absolute',
								left: '35%',
								right: '35%',
								top: 0,
								borderTop: (theme) =>
									`4px solid ${theme.palette.secondary.main}`,
								content: '""',
							},
							'&::after': {
								position: 'absolute',
								left: '35%',
								right: '35%',
								bottom: 0,
								borderBottom: (theme) =>
									`4px solid ${theme.palette.secondary.main}`,
								content: '""',
							},
							'& .MuiTypography-paragraph': {
								fontSize: '22px',
								fontStyle: 'italic',
							},
						}}
						{...props}
					/>
				),
			}}
		>
			{children}
		</ReactMarkdown>
	);
};

export default MarkdownContent;
