import {
	DrivenBox,
	DrivenContainer,
	DrivenFooter,
	DrivenGrid,
	DrivenIcon,
	DrivenLink,
	DrivenTypography,
	IconSizes,
	IconTypes,
	IconTypes_FontAwesome,
} from '@iterateai/driven-components';
import {
	Divider,
	styled,
	useTheme,
	useMediaQuery,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Heading from './heading';
import ImageBanner from './image-banner';
import MarkdownContent from './markdown-content';
import { StaticQuery, graphql } from 'gatsby';
import useLinkHref from '../hooks/use-link-href';
import * as React from 'react';
import { useState } from 'react';
import uuid4 from 'uuid4';

import { GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';
import { link } from 'fs';

const StyledFooterList = styled(DrivenBox)(({ theme }) => ({
	color: theme.palette.common.white,
}));

const FooterList = ({ title, links }) => {
	const theme = useTheme();

	return (
		<StyledFooterList theme={theme} component='nav' mb={3}>
			<Heading level='h2' style='subtitle1' text={title}></Heading>
			<List>
				{links.map((link: any, i: number) => (
					<ListItem disableGutters key={`footer-${title}-linklist-${i}`}>
						<DrivenLink
							color='white'
							variant='body2Bold'
							href={useLinkHref(link)}
							LinkComponent={GatsbyLink}
						>
							{link.text}
						</DrivenLink>
					</ListItem>
				))}
			</List>
		</StyledFooterList>
	);
};

export function Footer() {
	const theme = useTheme();

	return (
		<StaticQuery
			query={graphql`
				fragment FooterLinkFields on ContentfulLink {
					__typename
					id
					contentful_id
					text
					url
					target
					page {
						__typename
						id
						contentful_id
						path
					}
				}
				query FooterQuery {
					contentfulFooter(id: { eq: "b2475594-1e5c-5142-9834-c5e3638fea8e" }) {
						__typename
						contentful_id
						id
						aboutHeading
						aboutLinks {
							... on Node {
								... on ContentfulLink {
									...FooterLinkFields
								}
							}
						}
						franchisesHeading
						franchisesLinks {
							... on Node {
								... on ContentfulLink {
									...FooterLinkFields
								}
							}
						}
						moreHeading
						moreLinks {
							... on Node {
								... on ContentfulLink {
									...FooterLinkFields
								}
							}
						}
						contactHeading
						contactAddress {
							childMarkdownRemark {
								rawMarkdownBody
								html
							}
						}
						socialHeading
						logoLinksHeading
						logoLinks {
							__typename
							id
							contentful_id
							name
							... on Node {
								... on ContentfulLinkImage {
									link {
										...FooterLinkFields
									}
									image {
										gatsbyImageData(
											layout: FIXED
											height: 48
											quality: 100
											placeholder: TRACED_SVG
										)
										url
										mimeType
										publicUrl
										localFile {
											relativePath
										}
									}
								}
							}
						}
						copyright
						legalLinks {
							... on Node {
								... on ContentfulLink {
									...FooterLinkFields
								}
							}
						}
					}
				}
			`}
			render={(data) => {
				const {
					contentfulFooter: {
						aboutHeading,
						aboutLinks,
						franchisesHeading,
						franchisesLinks,
						moreHeading,
						moreLinks,
						contactHeading,
						contactAddress,
						logoLinksHeading,
						logoLinks,
						socialHeading,
						copyright,
						legalLinks,
					},
				} = data;

				const theme = useTheme();
				const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

				return (
					<DrivenBox component='footer' mt={6}>
						<DrivenBox
							component='section'
							py={{ xs: 5, sm: 6 }}
							backgroundColor={theme.palette.common.black}
							color={theme.palette.common.white}
						>
							<DrivenContainer>
								<DrivenGrid container>
									<DrivenGrid item xs={12} sm={6} md={3}>
										<FooterList title={aboutHeading} links={aboutLinks} />
									</DrivenGrid>

									<DrivenGrid item xs={12} sm={6} md={3}>
										<FooterList
											title={franchisesHeading}
											links={franchisesLinks}
										/>
									</DrivenGrid>

									<DrivenGrid item xs={12} sm={6} md={3}>
										<FooterList title={moreHeading} links={moreLinks} />
									</DrivenGrid>

									<DrivenGrid item xs={12} sm={6} md={3}>
										<Heading
											level='h2'
											style='subtitle1'
											text={contactHeading}
										/>

										<MarkdownContent>
											{contactAddress.childMarkdownRemark.rawMarkdownBody}
										</MarkdownContent>

										<DrivenTypography
											variant='caption'
											component='p'
											mt={3}
											sx={{
												display: 'flex',
												alignItems: 'center',
												columnGap: 2,
												textTransform: 'uppercase',

												a: {
													color: theme.palette.common.white,
												},
											}}
										>
											{socialHeading}
											<GatsbyLink
												href={`https://www.linkedin.com/company/driven-brands-inc-/`}
												target='_blank'
											>
												<DrivenIcon
													icon={IconTypes.LINKEDIN}
													size={IconSizes.MD}
												/>
												<DrivenTypography component='span' sx={visuallyHidden}>
													LinkedIn
												</DrivenTypography>
											</GatsbyLink>
										</DrivenTypography>
									</DrivenGrid>
								</DrivenGrid>
							</DrivenContainer>
						</DrivenBox>

						<ImageBanner heading={logoLinksHeading} images={logoLinks} />

						<DrivenContainer>
							<Divider />
						</DrivenContainer>

						<DrivenBox component='section' py={3}>
							<DrivenContainer>
								<DrivenBox
									display={{ md: 'flex' }}
									justifyContent={{ md: 'space-between' }}
									alignItems={{ md: 'flex-center' }}
									width={{ md: '100%' }}
								>
									<DrivenTypography
										component='div'
										flexGrow={1}
										flex-shrink={0}
										mt={{ md: -0.25 }}
										mb={{ xs: 1, md: 0 }}
									>
										{copyright}
									</DrivenTypography>
									<DrivenBox
										display='flex'
										flexDirection={{ xs: 'column', sm: 'row' }}
										justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
										flexWrap={{ sm: 'wrap' }}
										columnGap={4}
										rowGap={1}
									>
										{legalLinks.map((link: any, i: number) => (
											<DrivenLink
												variant='body2Bold'
												href={useLinkHref(link)}
												target={link.target}
												LinkComponent={GatsbyLink}
												key={`legal-links-${i}`}
											>
												{link.text}
											</DrivenLink>
										))}
									</DrivenBox>
								</DrivenBox>
							</DrivenContainer>
						</DrivenBox>
					</DrivenBox>
				);
			}}
		/>
	);
}

export default Footer;
